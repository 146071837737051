function checkScrollState() {
  let bodyElement = document.getElementsByTagName('body')[0];
  if (window.scrollY < 150) {
    bodyElement.classList.remove('scrolled');
  } else {
    bodyElement.classList.add('scrolled');
  }
}

window.onscroll = function () {
  checkScrollState()
};
checkScrollState();